<template>
  <v-list color="white" class="rounded">
    <v-list-item>
      <v-list-item-icon class="my-0 align-self-center"><v-icon>$vuetify.icons.camera</v-icon></v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title class="body-2 black--text">
          {{ $vuetify.lang.t('Your video limit:') }} <span class="font-weight-bold">{{ current }}</span> / {{ limit }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'VideoUsage',
  props: {
    current: {
      type: Number,
      default: 0,
    },
    limit: {
      type: [Number, String],
      default: 0,
    },
  },
};
</script>
